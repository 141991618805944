<template>
  <div class="page-kxUser">
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div>
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            rowKey="id"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :expandIconColumnIndex="1"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
          />
        </div>
      </div>
    </a-card>
    <SelectUmsUser ref="selectUmsUser"></SelectUmsUser>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import SelectUmsUser from './modules/SelectUmsUser'
import { groupList } from './services/commonTypes'
export default {
  components: {
    SelectUmsUser,
  },
  mixins: [queryViewMixin],

  data() {
    const tableColumns = [
      {
        title: '手机号码',
        width: '20%',
        align: 'center',
        dataIndex: 'phone',
      },
      {
        title: '用户名',
        width: '20%',
        align: 'center',
        dataIndex: 'umsAccountName',
      },
      {
        title: '角色',
        width: '20%',
        align: 'center',
        dataIndex: 'groupCode',
        customRender: (text, row, index) => {
          return <a-tag>{this.translateType(text, 'groupList')}</a-tag>
        },
      },
      {
        title: '操作',
        width: '20%',
        align: 'center',
        dataIndex: 'detail',
        customRender: (text, row, index) => {
          return (
            <div>
              <span>
                <a
                  class="row-action-btn"
                  onClick={() => {
                    this.remove(row)
                  }}
                >
                  移除组
                </a>
              </span>
            </div>
          )
        },
      },
    ]
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      tableColumns: tableColumns,
      dataSource: [],
    }
  },
  computed: {
    typesMap() {
      return {
        groupList,
      }
    },
  },

  methods: {
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const res = await this.$axios.post('/fast-repair/admin/projectUserPermission/queryGroupUser', {
          ...this.getPageParams(_pageNo, _pageSize),
          groupCode: 'k05',
        })
        const list = res.data.list
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {}
      this.loading = false
    },
    async add() {
      const commonCode = 'GROUP'
      const groupCode = 'k05'
      const permissionLevel = 0
      try {
        const row = await this.$refs.selectUmsUser.start()
        await this.$axios.post('/fast-repair/admin/projectUserPermission/add', {
          commonCode,
          groupCode,
          permissionLevel,
          umsAccountId: row.accountId,
          umsAccountName: row.userName,
        })
        this.$message.success('操作成功!')
        this.doQuery()
      } catch (e) {}
    },
    async remove(row) {
      await new Promise((resolve, reject) => {
        this.$confirm({
          title: '提示',
          content: '确定删除记录?',
          onOk: () => {
            resolve()
          },
          onCancel: () => {
            reject(new Error('E_CANCEL'))
          },
        })
      })
      await this.$axios.post('/fast-repair/admin/projectUserPermission/delete', {
        id: row.id,
      })
      this.$message.success('操作成功!')
      this.doQuery()
    },
  },
  mounted() {
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
